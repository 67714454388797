// Get SVGMap attributes
export function getLocationName(event) {
  return event.target.attributes.name.value;
}
export function getLocationId(event) {
  return event.target.attributes.id.value;
}

export function formatDate(param) {
  const date = new Date(param).toLocaleString("en-US", {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  });
  return date;
}

export function formatYear(param) {
  const date = new Date(param);
  return date.getFullYear();
}

export function objectToQueryString(obj) {
  return Object.keys(obj)
    .map((key) => key + "=" + obj[key])
    .join("&");
}

export function convertSlugToTitle(param, type) {
  let words = param.split("-");
  if (type === "capitalize") {
    return words
      .map(
        (word) => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase()
      )
      .join(" ");
  }
  if (type === "normal") {
    return words.map((word) => word).join(" ");
  }
}

export function removeUrlQuery(url) {
  return url.split("?")[0];
}

export function removeUrlSlash(url, startFrom) {
  return url.split("/")[startFrom];
}

export function convertToSlug(param) {
  let words = param.split(" ");
  return words.map((word) => word).join("-");
}

export function convertToBreakLine(param) {
  let words = param.split(" ");
  return words.map((word) => word).join("<br/>");
}

export function formatNumber(num) {
  return isNaN(num) ? num : Intl.NumberFormat().format(num);
}

export function parseLocaleNumber(stringNumber, locale) {
  var thousandSeparator = Intl.NumberFormat(locale)
    .format(11111)
    .replace(/\p{Number}/gu, "");
  var decimalSeparator = Intl.NumberFormat(locale)
    .format(1.1)
    .replace(/\p{Number}/gu, "");

  return parseFloat(
    stringNumber
      .replace(new RegExp("\\" + thousandSeparator, "g"), "")
      .replace(new RegExp("\\" + decimalSeparator), ".")
  );
}

export function formatLocaleCurrency(numberInput, locale = 'en-US', currency = 'IDR') {
  return new Intl.NumberFormat(locale, { style: "currency", currency: currency }).format(numberInput,)
}
