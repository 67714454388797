import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import useStore from "../store/store";

import LogoSearch from "../components/icons/Search.svg";
import LogoFlagID from "../components/icons/FlagID.svg";
import LogoFlagEN from "../components/icons/FlagEN.svg";
import Spinner from "./icons/Spinner/Spinner";

import en from "../locales/en";
import id from "../locales/id";

import LanguageItem from "./LanguageItem";
import Modal from "./Modal";
import SearchItem from "./SearchItem";

import apiProvider from "../lib/provider";

export default function SearchBar() {
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const { locale } = router;
  const setLocale = useStore(state => state.setLocale);
  const useLocale = useStore(state => state.locale);

  const changeLanguage = e => {
    e === "en" ? setLocale(en) : setLocale(id);
    setSearchTerm("");
    setResults([]);
    router.push(router.asPath, router.asPath, { locale: e });
  };

  const handleActiveLocale = e => {
    return locale === e ? true : false;
  };

  const getSearchResult = async query => {
    const searchResult = await fetch(apiProvider.SEARCH_URL + query, {
      headers: { accept: "application/json", "x-locale": locale }
    }).then(response => {
      setLoading(false);
      return response.json();
    });
    return searchResult;
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setLoading(true);
      const loadResults = async () => {
        if (!searchTerm) return setSearchTerm([]);

        if (searchTerm) {
          const res = await getSearchResult(searchTerm);
          setResults(res);
        }
      };
      loadResults();
    }, 200);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  let resultComponents = results.map((item, index) => {
    return (
      <div key={item.id} onClick={() => setOpen(false)}>
        <SearchItem data={item} />
      </div>
    );
  });

  return (
    <>
      <div className="bg-brand-blue-dark">
        <div className="flex items-center max-w-6xl px-4 py-2 mx-auto space-x-3 2xl:max-w-7xl">
          {/* Search Bar section */}
          <div
            onClick={() => setOpen(true)}
            className="flex items-center flex-1 p-1 -m-1 space-x-3 transition-colors duration-100 rounded cursor-pointer bg-white"
          >
            <div className="w-5 h-5 bg-brand-blue-dark">
              <LogoSearch className="text-white" />
            </div>
            <div className="text-sm tracking-wide text-sm tracking-wide text-brand-blue-dark opacity-25 opacity-25">
              {useLocale.searchForAnyContent}
            </div>
          </div>
          {/* Language section */}
          <div className="flex space-x-1">
            <LanguageItem
              flag={<LogoFlagID />}
              text={"ID"}
              isActive={handleActiveLocale("id")}
              onClick={() => changeLanguage("id")}
            />
            <LanguageItem
              flag={<LogoFlagEN />}
              text={"EN"}
              isActive={handleActiveLocale("en")}
              onClick={() => changeLanguage("en")}
            />
          </div>
        </div>
      </div>

      {/* Search Section */}
      <Modal selector="#modal" open={open} onClose={() => setOpen(false)}>
        <div className="flex items-center pb-1 border-b lg:space-x-2 lg:pb-3">
          <div className="w-4 h-4 lg:w-6 lg:h-6">
            <LogoSearch className="text-brand-blue-lighter" />
          </div>
          <div className="flex-1">
            <input
              type="text"
              placeholder={useLocale.search}
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              className="w-full text-sm tracking-wide placeholder-gray-300 border-none lg:text-base focus:ring-0"
            />
          </div>
          <div>{loading ? <Spinner data={{ size: 40 }} /> : null}</div>
        </div>
        <div>
          {results && results.length ? (
            <div className="border-t">
              <label className="inline-block py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase">
                {useLocale.searchResult}
              </label>
              <div className="space-y-1 overflow-hidden overflow-y-auto max-h-96 lg:max-h-120">
                {resultComponents}
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center h-32 text-gray-300">
              <div className="w-8 h-8 mb-1 lg:w-12 lg:h-12">
                <LogoSearch />
              </div>
              <p className="text-sm lg:text-lg">{useLocale.noResult}</p>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}
