import { objectToQueryString } from "./utils";
// SWR fetcher
// export const fetcher = (...args) => fetch(...args).then((res) => res.json());

// SWR fetcher with request options
export const fetcher = (url) =>
  fetch(url, {
    headers: {
      Accept: "application/json",
    },
  }).then((res) => res.json());

export const fetcherWithLocale = (url, locale) =>
  fetch(url, {
    headers: {
      Accept: "application/json",
      "x-locale": locale,
    },
    cache: 'no-store'
  }).then((res) => res.json());

export const testNewFetcher = (url, locale, params) =>
  fetch(url + params, {
    headers: {
      Accept: "application/json",
      "x-locale": locale,
    },
  }).then((res) => res.json());
