export default function LanguageItem({ flag, text, onClick, isActive }) {
  return (
    <button
      onClick={onClick}
      className={`${
        isActive
          ? "bg-white bg-opacity-20 hover:bg-opacity-20"
          : "text-opacity-30 hover:text-opacity-100 hover:bg-opacity-10"
      } flex items-center hover:bg-white p-2 rounded text-white text-xs tracking-wide transition-colors duration-100`}
      aria-label="Language Selector"
    >
      <div className="w-5 h-5 overflow-hidden bg-white rounded-full md:mr-2">
        {flag}
      </div>
      <span className="hidden md:block">{text}</span>
    </button>
  );
}
