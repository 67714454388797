var ARTICLES_URL = "".concat(process.env.NEXT_PUBLIC_BASE_URL, "/api/resources?resourcePageName=news-and-articles");
var PUBLICATIONS_URL = "".concat(process.env.NEXT_PUBLIC_BASE_URL, "/api/resources?resourcePageName=publications");
var STATISTICS_URL = "".concat(process.env.NEXT_PUBLIC_BASE_URL, "/api/resources?resourcePageName=cattle-statistics");
var REGULATORY_URL = "".concat(process.env.NEXT_PUBLIC_BASE_URL, "/api/resources?resourcePageName=regulatory-overview");
var TOOLKIT_COMPILATIONS_URL = "".concat(process.env.NEXT_PUBLIC_BASE_URL, "/api/resources?resourcePageName=toolkit-compilations");
var STAKEHOLDERS_URL = "".concat(process.env.NEXT_PUBLIC_BASE_URL, "/api/resources?resourcePageName=cattle-stakeholders");
var FILTER_URL = "".concat(process.env.NEXT_PUBLIC_BASE_URL, "/api/resource_pages/filters");
var GALLERY_URL = "".concat(process.env.NEXT_PUBLIC_BASE_URL, "/api/galleries?categories=");
var GALLERY_FILTER_URL = "".concat(process.env.NEXT_PUBLIC_BASE_URL, "/api/gallery_categories");
var SEARCH_URL = "".concat(process.env.NEXT_PUBLIC_BASE_URL, "/api/resources/search?q=");
var FINMOD_URL = "".concat(process.env.NEXT_PUBLIC_BASE_URL, "/api/finmod_business_models");
var PREPARATION_URL = "".concat(process.env.NEXT_PUBLIC_BASE_URL, "/api/preparation_types");
var ONLINE_INVESTMENT_URL = "".concat(process.env.NEXT_PUBLIC_BASE_URL, "/api/online_investments");
var RESOURCES_URL = "".concat(process.env.NEXT_PUBLIC_BASE_URL, "/api/resources");
var HOME_URL = "".concat(process.env.NEXT_PUBLIC_BASE_URL, "/api/homes");
var HOME_SECTIONS_URL = "".concat(process.env.NEXT_PUBLIC_BASE_URL, "/api/section_categories");
var FILTER = "&filters=";
var SORTYEAR = "&sort[year]=";
var PAGE = "&page=";
var ID = "&id=";
var ITEMS_PER_PAGE = "&itemsPerPage=";
var apiProvider = {
  RESOURCES_URL: RESOURCES_URL,
  ARTICLES_URL: ARTICLES_URL,
  FILTER_URL: FILTER_URL,
  PUBLICATIONS_URL: PUBLICATIONS_URL,
  STATISTICS_URL: STATISTICS_URL,
  REGULATORY_URL: REGULATORY_URL,
  TOOLKIT_COMPILATIONS_URL: TOOLKIT_COMPILATIONS_URL,
  STAKEHOLDERS_URL: STAKEHOLDERS_URL,
  GALLERY_URL: GALLERY_URL,
  GALLERY_FILTER_URL: GALLERY_FILTER_URL,
  SEARCH_URL: SEARCH_URL,
  FINMOD_URL: FINMOD_URL,
  FILTER: FILTER,
  SORTYEAR: SORTYEAR,
  PAGE: PAGE,
  ID: ID,
  ITEMS_PER_PAGE: ITEMS_PER_PAGE,
  PREPARATION_URL: PREPARATION_URL,
  ONLINE_INVESTMENT_URL: ONLINE_INVESTMENT_URL,
  HOME_URL: HOME_URL,
  HOME_SECTIONS_URL: HOME_SECTIONS_URL
};
export default apiProvider;