import { useEffect, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import LogoMenu from "../components/icons/Menu.svg";
import LogoRMCP from "../components/icons/RMCP.svg";
import LogoBKPMEN from "../components/icons/BKPM-en.svg";
import LogoAustralianGov from "../components/icons/AustralianGov.svg";
import useStore from "../store/store";
import NavItem from "./NavItem";

const NavBar = () => {
  const useLocale = useStore(state => state.locale);
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();

  // console.log(router.asPath);
  useEffect(() => {
    setIsOpen(false);
  }, [router.asPath]);

  const dropdownMenu = {
    preparation: [
      {
        id: 0,
        name: useLocale.preOperational.title,
        url: "/preparation/pre-operational"
      },
      {
        id: 1,
        name: useLocale.operational.title,
        url: "/preparation/operational"
      }
    ],
    resources: [
      {
        id: 2,
        name: useLocale.resourcesTab.stakeholders,
        url: "/resources/cattle-stakeholders"
      },
      {
        id: 3,
        name: useLocale.resourcesTab.regulatoryOverview,
        url: "/resources/regulatory-overview"
      },
      {
        id: 4,
        name: useLocale.resourcesTab.newsAndArticles,
        url: "/resources/news-and-articles"
      },
      {
        id: 5,
        name: useLocale.resourcesTab.publications,
        url: "/resources/publications"
      },
      {
        id: 6,
        name: useLocale.resourcesTab.statistics,
        url: "/resources/cattle-statistics"
      }
    ],
    about: [
      {
        id: 8,
        name: useLocale.aboutUs,
        url: "/about/about-us"
      },
      {
        id: 9,
        name: useLocale.acknowledgement,
        url: "/acknowledgement"
      },
      {
        id: 10,
        name: useLocale.contactUs,
        url: "/contact-us"
      }
    ]
  };

  return (
    <div className="py-1 bg-white border-b">
      <div className="relative">
        <div className="inset-x-0 flex items-center max-w-6xl px-4 mx-auto 2xl:max-w-7xl">
          <Link href="/">
            <a
              className="z-10 lg:absolute"
              aria-label="Logo of the investincattle Indonesia"
            >
              <div className="w-auto h-9 xl:h-14">
                <img
                  className="object-contain h-full"
                  src="/assets/img/gapensiska.png"
                  width="140"
                  height="48"
                  alt="GAPENSISKA Logo"
                />
              </div>
              {/* <LogoRMCP className="w-auto h-7 xl:h-10" /> */}
            </a>
          </Link>
          <div className="h-8 mx-3 border-r lg:hidden" />
          <div className="flex-1 hidden lg:flex" />
          <div className="flex flex-1 space-x-5 lg:flex-initial w-auto h-8 lg:h-12">
            {/* <LogoAustralianGov className="w-auto h-8 lg:h-12" /> */}
            {/* <LogoBKPMEN className="w-auto h-8 lg:h-10 xl:h-12" /> */}
            {/* <div className="w-auto h-8 lg:h-12">
              <img
                className="object-contain h-full"
                src="/assets/logo-ministry.webp"
                width="145"
                height="48"
                alt="Badan Koordinasi Penanaman Modal (BKPM) Logo"
              />
            </div> */}
          </div>
          <div
            onClick={() => setIsOpen(!isOpen)}
            className="p-1 border rounded-md cursor-pointer lg:hidden"
          >
            <LogoMenu className="w-auto h-6 text-brand-blue-dark" />
          </div>

          <div
            onMouseLeave={() => setIsOpen(false)}
            className="absolute top-0 right-0 z-0 flex lg:right-32 lg:left-0 mt-14 lg:mt-0 lg:inset-y-0 lg:items-center lg:w-full"
          >
            <ul
              className={`${!isOpen &&
                "hidden"} bg-white lg:flex lg:flex-wrap lg:w-120 lg:justify-center divide-y lg:divide-none xl:w-auto lg:mx-auto border lg:border-none shadow-md lg:shadow-none py-2 rounded-md`}
            >
              <NavItem href="/investor-toolkit">
                {useLocale.investorToolkit}
              </NavItem>
              <NavItem
                href="/preparation/pre-operational"
                dropdownMenu={dropdownMenu.preparation}
              >
                {useLocale.preparation}
              </NavItem>
              <NavItem href="/financial-modeling">
                {useLocale.financialModelling}
              </NavItem>
              <NavItem href="/toolkit-compilations">
                {useLocale.toolkitCompilations}
              </NavItem>
              <NavItem
                href="/resources/cattle-stakeholders"
                dropdownMenu={dropdownMenu.resources}
              >
                {useLocale.resources}
              </NavItem>
              <NavItem href="/gallery">{useLocale.gallery}</NavItem>
              <NavItem href="/about/about-us" dropdownMenu={dropdownMenu.about}>
                {useLocale.about}
              </NavItem>
              <NavItem href="/links">{useLocale.links}</NavItem>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
