import LogoRMCP from "../components/icons/RMCP.svg";
import LogoLocation from "../components/icons/Location.svg";
import LogoPhone from "../components/icons/Phone.svg";
import LogoEmail from "../components/icons/Email.svg";
import LogoTwitter from "../components/icons/twitter-brands.svg";
import LogoFacebook from "../components/icons/facebook-f-brands.svg";
import LogoInstagram from "../components/icons/instagram-brands.svg";
import LogoYoutube from "../components/icons/youtube-brands.svg";
import LogoLinkedin from "../components/icons/linkedin-in-brands.svg";
import useSWR from "swr";
import { fetcher, fetcherWithLocale } from "../lib/useRequest";
import { useRouter } from "next/router";
import useStore from "../store/store";

const PROFILES_URL = `${process.env.NEXT_PUBLIC_BASE_URL}/api/profiles`;

export const getStaticProps = async ({ locale }) => {
  const profiles = await fetcherWithLocale(`${PROFILES_URL}`, locale);

  return {
    props: { profiles },
    revalidate: true,
  };
};

const Footer = props => {
  const router = useRouter();
  const { locale } = router;
  const { data: profiles } = useSWR(
    [`${PROFILES_URL}`, locale],
    fetcherWithLocale,
    { initialData: props.profiles }
  );

  const useLocale = useStore(state => state.locale);

  // console.log(useLocale);

  return (
    <>
      <footer className="border-t bg-gray-100">
        <div className="mx-auto max-w-6xl 2xl:max-w-7xl px-4 py-8 md:py-12">
          <div className="flex flex-col md:space-x-12 md:flex-row space-y-4">
            <div className="border-t w-full md:w-64 md:pt-0 md:border-t-0 md:mt-0">
              <p className="text-xs mt-4">
                {profiles && profiles[0] && profiles[0].translation
                  ? profiles[0].translation.name
                  : ""}
              </p>
              <div className="flex flex-wrap text-xs mt-4">
                {profiles && profiles[0] && profiles[0].profile_social_media
                  ? profiles[0].profile_social_media.map((socialMedia, id) => {
                      if (socialMedia.social_media.name == "Facebook") {
                        return (
                          <a
                            href={socialMedia.url}
                            target="_blank"
                            rel="noreferrer noopener nofollow"
                            className="inline-block mb-2 mr-4 transition-colors duration-300 ease-in-out group hover:text-brand-blue-dark"
                            key="facebook-footer"
                          >
                            <span className="relative flex items-center space-x-1.5 font-medium">
                              <LogoFacebook className="h-3.5 text-gray-400" />
                              <span>Facebook</span>
                            </span>
                          </a>
                        );
                      }

                      if (socialMedia.social_media.name == "Twitter") {
                        return (
                          <a
                            href={socialMedia.url}
                            target="_blank"
                            rel="noreferrer noopener nofollow"
                            className="inline-block mb-2 mr-4 transition-colors duration-300 ease-in-out group hover:text-brand-blue-dark"
                            key="twitter-footer"
                          >
                            <span className="relative flex items-center space-x-1.5 font-medium">
                              <LogoTwitter className="h-3.5 text-gray-400" />
                              <span>Twitter</span>
                            </span>
                          </a>
                        );
                      }

                      // if (socialMedia.social_media.name == "Linkedin") {
                      //   return (
                      //     <a
                      //       href={socialMedia.url}
                      //       target="_blank"
                      //       rel="noreferrer noopener"
                      //       className="inline-block mb-2 mr-4 transition-colors duration-300 ease-in-out group hover:text-brand-blue-dark"
                      //       key="linkedin-footer"
                      //     >
                      //       <span className="relative flex items-center space-x-1.5 font-medium">
                      //         <LogoLinkedin className="h-3.5 text-gray-400" />
                      //         <span>LinkedIn</span>
                      //       </span>
                      //     </a>
                      //   );
                      // }

                      if (socialMedia.social_media.name == "Youtube") {
                        return (
                          <a
                            href={socialMedia.url}
                            target="_blank"
                            rel="noreferrer noopener nofollow"
                            className="inline-block mb-2 mr-4 transition-colors duration-300 ease-in-out group hover:text-brand-blue-dark"
                            key="youtube-footer"
                          >
                            <span className="relative flex items-center space-x-1.5 font-medium">
                              <LogoYoutube className="h-3.5 text-gray-400" />
                              <span>YouTube</span>
                            </span>
                          </a>
                        );
                      }

                      if (socialMedia.social_media.name == "Instagram") {
                        return (
                          <a
                            href={socialMedia.url}
                            target="_blank"
                            rel="noreferrer noopener nofollow"
                            className="inline-block mb-2 mr-4 transition-colors duration-300 ease-in-out group hover:text-brand-blue-dark"
                            key="instagram-footer"
                          >
                            <span className="relative flex items-center space-x-1.5 font-medium">
                              <LogoInstagram className="h-3.5 text-gray-400" />
                              <span>Instagram</span>
                            </span>
                          </a>
                        );
                      }
                    })
                  : ""}
              </div>
            </div>
            <div className="md: w-1/8">
              <div className="pb-3 md:pb-0 space-y-1.5">
                <span className="text-sm font-medium text-black/40">Menu</span>
                <ul className="text-sm text-black/70">
                  <li>
                    <a href="/" className="hover:text-brand-blue-dark">
                      {useLocale.home}
                    </a>
                  </li>
                  <li>
                    <a href="/about" className="hover:text-brand-blue-dark">
                      {useLocale.aboutUs}
                    </a>
                  </li>
                  <li>
                    <a href="/news" className="hover:text-brand-blue-dark">
                      {useLocale.news}
                    </a>
                  </li>
                  <li>
                    <a href="/links" className="hover:text-brand-blue-dark">
                      {useLocale.ourPartner}
                    </a>
                  </li>
                  <li>
                    <p>
                      {useLocale.faq}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="md:flex-1">
              <div className="pb-3 md:pb-0 space-y-1.5">
                <span className="text-sm font-medium text-black/40">{useLocale.address}</span>
                <div>
                  {/* <p className="font-semibold">
                    {profiles && profiles[0] && profiles[0].translation
                      ? profiles[0].translation.address_name
                      : ""}
                  </p>
                  <p className="mt-1.5 text-sm text-black/70">
                    {profiles && profiles[0] && profiles[0].translation
                      ? profiles[0].translation.address_building
                      : ""}
                  </p> */}
                  <p className="text-sm text-black/70 mt-0.5">
                    {profiles && profiles[0] && profiles[0].translation
                      ? profiles[0].translation.address_detail
                      : ""}
                  </p>                 
                  {/* <div className="mt-4 text-sm font-medium md:space-x-6 lg:flex">
                    <div className="flex items-center space-x-2">
                      <LogoPhone className="w-5 text-gray-400" />
                      <p>{profiles && profiles[0] ? profiles[0].phone : ""}</p>
                    </div>
                    <div className="flex items-center space-x-2">
                      <LogoEmail className="w-5 text-gray-400" />
                      <p className="mt-1 lg:mt-0">
                        {profiles && profiles[0] ? profiles[0].email : ""}
                      </p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="flex-1 flex-shrink-0 pt-6 md:pt-0">
              <div className="space-y-1.5">
                <p className="text-sm font-medium text-black/40">
                  {useLocale.contact}
                </p>
                <div className="mt-4 text-sm font-medium">
                  <div className="flex flex-col">
                    {/* <LogoPhone className="w-5 text-gray-400" /> */}
                    {/* <p>{profiles && profiles[0] ? profiles[0].phone : ""}</p> */}
                    {profiles && profiles[0] ? (profiles[0].phone.split(",") || [])?.map(x => 
                      <p key={x}>{x}</p>) : ""}
                  </div>
                  <div className="flex flex-col mt-4">
                    {/* <LogoEmail className="w-5 text-gray-400" /> */}
                    {/* <p className="mt-1 lg:mt-0">
                      {profiles && profiles[0] ? profiles[0].email : ""}
                    </p> */}
                    {profiles && profiles[0] ? (profiles[0].email.split(",") || [])?.map(x => 
                      <p key={x}>{x}</p>) : ""}
                  </div>
                </div> 
                {/* <a
                  href={
                    "https://" +
                    (profiles && profiles[0] ? profiles[0].website : "")
                  }
                  target="_blank"
                  rel="nofollow"
                  className="relative inline-block font-semibold transition-colors duration-300 ease-in-out hover:text-brand-blue-dark group"
                >
                  {profiles && profiles[0] ? profiles[0].website : ""}
                </a> */}
              </div>
              {/* <div className="space-y-1.5 pt-3 mt-3">
                <span className="text-sm font-medium text-black/40">
                  {useLocale.ourSocialMedia}
                </span>
              </div> */}
            </div>
          </div>

          <hr className="my-6" />

          <div className="text-xs text-gray-500">
            <strong>Disclaimer:</strong> { profiles && profiles[0].translation.disclaimer }
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
