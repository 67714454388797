import { useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { removeUrlQuery } from "../lib/utils";

export default function NavItem({ href, dropdownMenu, children }) {
  const router = useRouter();
  const [dropdown, setDropdown] = useState(false);
  const menus = dropdownMenu;
  const onMouseEnter = () => {
    setDropdown(true);
  };
  const onMouseLeave = () => {
    setDropdown(false);
  };

  return (
    <li
      className="relative"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Link href={href}>
        <a
          className={`${
            router.asPath === href || removeUrlQuery(router.asPath) === href
              ? "text-brand-blue-light bg-brand-blue-lighter bg-opacity-10"
              : "text-gray-700"
          } text-xs uppercase block font-semibold lg:px-1 xl:px-2 px-4 py-2 lg:py-1 xl:py-2 hover:bg-brand-blue-lighter hover:bg-opacity-10 hover:text-brand-blue-light transition-colors duration-200 rounded`}
        >
          {children}
        </a>
      </Link>

      {/* {menus && (
        <ul>
          {menus.map((menu) => (
            <Link key={menu.id} href={menu.url}>
              <a
                onClick={onMouseLeave}
                className={`${
                  router.asPath === menu.url ||
                  removeUrlQuery(router.asPath) === menu.url
                    ? "text-brand-blue-light bg-brand-blue-lighter bg-opacity-5"
                    : "text-gray-700"
                } text-xs uppercase tracking-wide block font-semibold px-4 py-2 hover:bg-brand-blue-lighter hover:bg-opacity-10 hover:text-brand-blue-light transition-colors duration-200`}
              >
                {menu.name}
              </a>
            </Link>
          ))}
        </ul>
      )} */}

      {dropdown && menus && (
        <div className="absolute z-10 w-48 lg:pt-1 xl:pt-5 -inset-x-48 -inset-y-1 lg:inset-y-6 lg:-inset-x-10">
          <div className="py-2 bg-white border rounded-md">
            {menus.map(menu => (
              <Link key={menu.id} href={menu.url}>
                <a
                  onClick={onMouseLeave}
                  className={`${
                    router.asPath === menu.url ||
                    removeUrlQuery(router.asPath) === menu.url
                      ? "text-brand-blue-light bg-brand-blue-lighter bg-opacity-5"
                      : "text-gray-700"
                  } text-ss uppercase tracking-wide block font-semibold px-4 py-2 hover:bg-brand-blue-lighter hover:bg-opacity-10 hover:text-brand-blue-light transition-colors duration-200`}
                >
                  {menu.name}
                </a>
              </Link>
            ))}
          </div>
        </div>
      )}
    </li>
  );
}
